@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
}

/* body {
  user-select: none;
} */

.solution-header {
  height: 105px;
  filter: blur(50px);
}

.react-tel-input .selected-flag {
  background-color: transparent !important;
}
.react-tel-input .selected-flag .flag .arrow {
  display: none !important;
}

.react-tel-input .selected-flag.open .flag .arrow.up {
  display: none !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  padding: 0px 4px !important;
  border: none !important;
  border-right: 1px solid rgba(161, 214, 201, 0.8) !important;
}

.react-tel-input .flag-dropdown:hover {
  background-color: transparent !important;
  padding: 0px 4px !important;
  border: none;
}
.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
  padding: 0px 4px !important;
  border: none;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
  padding: 0px 4px !important;
  border: none;
}

.react-tel-input .country-list .search {
  padding: 4px 3px 6px 4px !important;
}
.react-tel-input input:hover {
  border: 1px solid white !important;
}
.react-tel-input input.styleWhiteBold:hover {
  border: 2.8px solid white !important;
}
.react-tel-input input.styleGray:hover {
  border: 1px solid #4d505d !important;
}

.react-tel-input input.styleGrayBold:hover {
  border: 2.8px solid #4d505d !important;
}

.react-tel-input input::placeholder {
  color: #fff;
}
.react-tel-input input.styleGray::placeholder {
  color: rgba(77, 80, 93, 0.6);
}
.react-tel-input input.styleGray:focus::placeholder {
  color: #4d505d;
}
.react-tel-input .form-control {
  padding-left: 52px !important;
}

.active {
  border-bottom: 3px solid #fff;
}

.half-gradient-image {
  background: linear-gradient(
      to right,
      rgba(13, 158, 145, 0.1) 2%,
      rgba(13, 158, 145, 0.8) 80%,
      rgba(13, 158, 145, 0.2) 100%
    ),
    url("/src/assets/images/global/headerbackground.png");
  background-size: cover;
}
.half-blur-effect::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: rgba(13, 158, 145, 0.6);
  backdrop-filter: blur(20px); /* Adjust the blur effect as needed */
}

input[type="checkbox"] {
  accent-color: #15ac8e;
}

.banner1 {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background: url("/src/assets/images/global/banner1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.banner2 {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background: url("/src/assets/images/global/banner1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  transform: scaleY(-1);
}

.landlordBanner1 {
  width: 100%;
  background: url("/src/assets/images/global/background2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.landlordBanner2 {
  width: 100%;
  min-height: 100%;
  background: url("/src/assets/images/global/background2.png");

  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  transform: scaleY(-1);
}
.fullBackground {
  width: 100%;
  background: url("/src/assets/images/global/background2_3.jpg");
  background-size: 100%;
  background-repeat: repeat;
}

.handImgLandlord {
  width: 100%;
  background: url("/src/assets/images/landlords/11_6.png");
  background-size: cover;
}
.handImgTenants {
  width: 100%;
  background: url("/src/assets/images/tenants/22_1.png");
  background-size: cover;
}

.privacyBanner {
  width: 100%;
  background: url("/src/assets/images/global/background2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  /* backdrop-filter: blur(5px); Use backdrop-filter for blur effect */
  z-index: 1000;
  opacity: 0.7;
}

.consent-form {
  z-index: 1001;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100vh;
  font-size: 14px;
}

.cookie-link {
  text-decoration: underline;
}

.rmsc.lightStyle {
  --rmsc-main: #fff;
  --rmsc-hover: #d9d9d9;
  --rmsc-selected: #e3fbf3;
  --rmsc-border: rgba(237, 247, 245, 0.8);
  --rmsc-gray: #fff;
  --rmsc-bg: #e3fbf3;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 36px;
  color: #31435b;
}
.rmsc.lightStyle:hover {
  --rmsc-border: #fff;
}

.rmsc.lightStyle .dropdown-container {
  background-color: transparent;
}

.rmsc.lightStyle .dropdown-container .dropdown-heading-value {
  color: #fff;
}
.rmsc.lightStyle.filledBorder .dropdown-container {
  border: none;
  outline: none;
}
.rmsc.lightStyle.filledBorder {
  outline: none;
  border: 2.8px solid white;
  border-radius: 4px;
}
.rmsc.darkStyle {
  --rmsc-main: #31435b;
  --rmsc-hover: #d9d9d9;
  --rmsc-selected: #e3fbf3;
  --rmsc-border: rgba(77, 80, 93, 0.6);
  --rmsc-gray: #31435b;
  --rmsc-bg: #e3fbf3;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 36px;
  color: #31435b;
  border: 1px;
}

.rmsc.darkStyle .dropdown-container {
  background-color: transparent;
}

.rmsc.darkStyle.filledBorder .dropdown-container {
  border: none;
  outline: none;
}
.rmsc.darkStyle:hover {
  --rmsc-border: #31435b;
}
.rmsc.darkStyle.filledBorder {
  outline: none;
  border: 2.8px solid #31435b;
  border-radius: 4px;
}

.rmsc.darkStyle .dropdown-container .dropdown-heading-value {
  color: #31435b;
}

/* .landlordBannerFlip {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: url("/src/assets/images/global/background2.png");
  background-size: cover;
  background-repeat: no-repeat;
  transform: scaleX(-1);
} */
